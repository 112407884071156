// ServicesSection.js
import React from "react"

function ServicesSection() {
    const services = [
        {
            id: "call-centre",
            titleStart: "Call Centre",
            titleHighlight: "Optimisation",
            description: "We help call centres improve their efficiency and effectiveness through various services, such as call routing automation, agent performance management, and customer self-service solutions."
        },
        {
            id: "software-dev",
            titleStart: "Software",
            titleHighlight: "Development",
            description: "We have a proven track record of building custom software solutions that meet our clients' specific needs. We use agile development methodologies to ensure that projects are delivered on time and within budget."
        },
        {
            id: "customer-service",
            titleStart: "Customer Service",
            titleHighlight: "Solutions",
            description: "Let us improve your customer service operations by implementing solutions such as chatbots, knowledge base articles, and self-service portals."
        },
        {
            id: "digital-transform",
            titleStart: "Digital",
            titleHighlight: "Transformation",
            description: "We develop and implement digital transformation strategies that help improve your business processes, reduce costs, and increase revenue."
        },
        {
            id: "fractional-cto",
            titleStart: "Fractional",
            titleHighlight: "CTO",
            description: "We provide services to help businesses improve their infrastructure and operations. We work closely with our clients to understand their business goals and objectives, and then provide the technical expertise and support needed to achieve them."
        },
        {
            id: "cloud-migration",
            titleStart: "Cloud",
            titleHighlight: "Migration",
            description: "We help businesses migrate their existing systems and applications to the cloud, enabling them to scale their operations and improve their overall efficiency."
        }
    ]

    return (
        <section id="services" className="py-16 px-4">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-3xl font-bold mb-16 text-white text-center">
                    Our Services
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {services.map((service) => (
                        <div
                            key={service.id}
                            className="bg-white rounded-xl shadow-lg p-8 flex flex-col h-full"
                        >
                            <h3 className="text-xl font-bold mb-4 text-primary text-center">
                                {service.titleStart}{' '}
                                <span className="text-secondary">{service.titleHighlight}</span>
                            </h3>
                            <p className="text-gray-600 text-center">
                                {service.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ServicesSection
