import React from 'react';

function SupportedChannels() {
  const channels = [
    {
      id: 'voice',
      name: "Voice",
      icon: "bx bx-phone",
      description: "Traditional and VoIP calling solutions"
    },
    {
      id: 'instagram',
      name: "Instagram",
      icon: "bx bxl-instagram",
      description: "Direct messages and comments management"
    },
    {
      id: 'facebook',
      name: "Facebook",
      icon: "bx bxl-facebook",
      description: "Messenger and comment integration"
    },
    {
      id: 'in-app',
      name: "In-App",
      icon: "bx bx-mobile-alt",
      description: "Native mobile app messaging"
    },
    {
      id: 'sms',
      name: "SMS",
      icon: "bx bx-message",
      description: "Text messaging services"
    },
    {
      id: 'push-notifications',
      name: "Push Notifications",
      icon: "bx bx-bell",
      description: "Mobile and web push alerts"
    },
    {
      id: 'whatsapp',
      name: "WhatsApp",
      icon: "bx bxl-whatsapp",
      description: "WhatsApp Business integration"
    },
    {
      id: 'open-channel',
      name: "Open Channel",
      icon: "bx bx-chat",
      description: "Custom channel integration"
    },
    {
      id: 'rcs',
      name: "RCS",
      icon: "bx bx-message-rounded-detail",
      description: "Rich Communication Services"
    },
    {
      id: 'email',
      name: "Email",
      icon: "bx bx-envelope",
      description: "Email communication management"
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4">Supported Communication Channels</h2>
        <p className="text-center text-gray-600 mb-12">
          Seamlessly integrate and manage all your customer communications through our preferred partner platform
        </p>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {channels.map((channel) => (
            <div
              key={channel.id}
              className="flex flex-col items-center p-6 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-300"
            >
              <i className={`${channel.icon} text-4xl text-secondary mb-4`}></i>
              <h3 className="text-lg font-semibold text-primary mb-2 text-center">{channel.name}</h3>
              <p className="text-sm text-gray-600 text-center">{channel.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SupportedChannels;
