import React from "react"

function DevelopmentStack() {
    const techStacks = [
        {
            id: "frontend",
            title: "Frontend",
            technologies: [
                { name: "React", icon: "bx bxl-react" },
                { name: "Flutter", icon: "bx bxl-flutter" },
                { name: "Angular", icon: "bx bxl-angular" },
                { name: "Vue.js", icon: "bx bxl-vuejs" },
            ],
        },
        {
            id: "backend",
            title: "Backend",
            technologies: [
                { name: "Typescript", icon: "bx bxl-typescript" },
                { name: "Golang", icon: "bx bxl-go-lang" },
                { name: "Python", icon: "bx bxl-python" },
                { name: "PHP", icon: "bx bxl-php" },
            ],
        },
        {
            id: "cloud",
            title: "Cloud & Infrastructure",
            technologies: [
                { name: "AWS", icon: "bx bxl-aws" },
                { name: "Firebase", icon: "bx bxl-firebase" },
                { name: "Docker", icon: "bx bxl-docker" },
                { name: "Kubernetes", icon: "bx bx-cube" },
            ],
        },
        {
            id: "database",
            title: "Databases",
            technologies: [
                { name: "MongoDB", icon: "bx bxl-mongodb" },
                { name: "DynamoDB", icon: "bx bx-data" },
                { name: "PostgreSQL", icon: "bx bx-data" },
                { name: "MySQL", icon: "bx bx-data" },
            ],
        },
    ]

    return (
        <section id="tech-stack" className="py-16 bg-neutralLight text-center">
            <div className="max-w-6xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-4 text-white">
                    Development Stack
                </h2>
                <p className="text-center text-gray-300 mb-12">
                    We leverage modern technologies to build scalable and robust
                    solutions
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {techStacks.map((stack) => (
                        <div
                            key={stack.id}
                            className="bg-white rounded-xl p-6 shadow-sm"
                        >
                            <h3 className="text-xl font-bold text-primary mb-4 text-center">
                                {stack.title}
                            </h3>
                            <div className="grid grid-cols-2 gap-4">
                                {stack.technologies.map((tech, index) => (
                                    <div
                                        key={`${stack.id}-${index}`}
                                        className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
                                    >
                                        <i
                                            className={`${tech.icon} text-2xl text-secondary`}
                                        ></i>
                                        <span className="text-gray-700">
                                            {tech.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default DevelopmentStack
