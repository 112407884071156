// App.js
import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ServicesSection from './ServicesSection';
import WhyChooseUs from './WhyChooseUs';
import PastProjects from './PastProjects';
import ContactUs from './ContactUs';
import Footer from './Footer';
import AnimatedBackground from './AnimatedBackground';
import OurPartners from './OurPartners';
import SupportedChannels from './SupportedChannels';
import DevelopmentStack from './DevelopmentStack';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="relative overflow-hidden">
      <AnimatedBackground />
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10">
        <Header />
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <WhyChooseUs />
        <DevelopmentStack />
        <OurPartners />
        <SupportedChannels />
        <PastProjects />
        <ContactUs />
        <Footer />
      </div>
      <ScrollToTop />
    </div>
  );
}

export default App;
