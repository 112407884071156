// HeroSection.js
import React from "react"

function HeroSection() {
    return (
        <section
            className="relative bg-cover bg-center text-white py-16 md:py-32"
            aria-label="Hero section"
        >
            <div className="relative z-10 max-w-3xl mx-auto text-center px-4">
                <h1
                    className="text-3xl md:text-4xl font-bold mb-4 leading-relaxed md:leading-normal"
                    itemProp="headline"
                >
                    Elevating{" "}
                    <span className="block md:inline my-2 md:my-0">
                        <span className="text-secondary">[</span>
                        customer experience
                        <span className="text-secondary">]</span>
                    </span>{" "}
                    <span className="block md:inline mt-2 md:mt-0">
                        through innovation and automation
                    </span>
                </h1>
                <meta itemProp="description" content="Elevating customer experience through innovation and automation" />
            </div>
        </section>
    )
}

export default HeroSection
