// Header.js
import React, { useState } from "react"
import logo from "./assets/logo.png"

function Header() {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <header className="bg-white shadow-md">
            <div className="container mx-auto flex items-center justify-between py-4 px-6">
                <a href="/">
                    <img src={logo} alt="Codified Logo" className="h-10" />
                </a>
                <nav className="hidden md:flex space-x-6">
                    <a
                        href="#about"
                        className="text-gray-700 hover:text-primary"
                    >
                        About
                    </a>
                    <a
                        href="#services"
                        className="text-gray-700 hover:text-primary"
                    >
                        Services
                    </a>
                    <a
                        href="#why-us"
                        className="text-gray-700 hover:text-primary"
                    >
                        Why Choose Us
                    </a>
                    <a
                        href="#partners"
                        className="text-gray-700 hover:text-primary"
                    >
                        Partners
                    </a>
                    <a
                        href="#projects"
                        className="text-gray-700 hover:text-primary"
                    >
                        Projects
                    </a>
                    <a
                        href="#contact"
                        className="text-gray-700 hover:text-primary"
                    >
                        Contact
                    </a>
                </nav>
                <div className="md:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-gray-700 hover:text-primary focus:outline-none focus:text-primary"
                    >
                        {/* Menu Icon */}
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            {isOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </button>
                </div>
            </div>
            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden bg-white shadow-md">
                    <nav className="px-2 pt-2 pb-4 space-y-1">
                        <a
                            href="#about"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            About
                        </a>
                        <a
                            href="#services"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            Services
                        </a>
                        <a
                            href="#why-us"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            Why Choose Us
                        </a>
                        <a
                            href="#projects"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            Projects
                        </a>
                        <a
                            href="#partners"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            Partners
                        </a>
                        <a
                            href="#contact"
                            className="block text-gray-700 hover:text-primary px-4 py-2"
                        >
                            Contact
                        </a>
                    </nav>
                </div>
            )}
        </header>
    )
}

export default Header
