// src/OurPartners.js
import React from "react"
import awsLogo from "./assets/aws-logo.svg"
import infobipLogo from "./assets/infobip-logo.svg"
import orangePillLogo from "./assets/orangepill-logo.webp"
import innerRealityLogo from "./assets/irl-logo.webp"
import trilogyLogo from "./assets/trilogy-logo.webp"

function OurPartners() {
    const partners = [
        {
            id: "aws",
            name: "AWS",
            logo: awsLogo,
            website: "https://aws.amazon.com/",
            height: "h-16",
        },
        {
            id: "infobip",
            name: "Infobip",
            logo: infobipLogo,
            website: "https://www.infobip.com/",
            height: "h-16",
        },
        {
            id: "orangepill",
            name: "OrangePill",
            logo: orangePillLogo,
            website: "https://orangepill.com/",
            height: "h-8 self-center",
        },
        {
            id: "inner-reality",
            name: "Inner Reality",
            logo: innerRealityLogo,
            website: "https://innerreality.co.za/",
            height: "h-14",
        },
        {
            id: "trilogy",
            name: "Trilogy",
            logo: trilogyLogo,
            website: "https://trilogybpo.com/",
            height: "h-14 bg-black w-52",
        },
    ]

    return (
        <section id="partners" className="py-16 bg-neutralLight text-center">
            <h2 className="text-3xl font-bold text-white mb-10 px-4">
                Our Partners
            </h2>
            <div className="px-4 lg:px-4">
                <div className="max-w-4xl mx-auto bg-white rounded-md shadow-md p-6">
                    <div className="flex flex-col gap-8 md:grid md:grid-cols-3">
                        {/* First row */}
                        <div className="flex flex-col md:flex-row md:col-span-3 justify-around items-center md:mb-8 space-y-8 md:space-y-0">
                            {partners.slice(0, 3).map((partner) => (
                                <div
                                    key={partner.id}
                                    className="w-full md:w-auto flex justify-center"
                                >
                                    <a
                                        href={partner.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="transform hover:scale-105 transition duration-300"
                                        aria-label={`Visit ${partner.name}'s website`}
                                    >
                                        <img
                                            src={partner.logo}
                                            alt={`${partner.name} Logo`}
                                            className={`mx-auto ${partner.height}`}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                        {/* Second row */}
                        <div className="flex flex-col md:flex-row md:col-span-3 justify-center gap-8 md:gap-20">
                            {partners.slice(3).map((partner) => (
                                <div
                                    key={partner.id}
                                    className="w-full md:w-auto flex justify-center"
                                >
                                    <a
                                        href={partner.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="transform hover:scale-105 transition duration-300"
                                        aria-label={`Visit ${partner.name}'s website`}
                                    >
                                        <img
                                            src={partner.logo}
                                            alt={`${partner.name} Logo`}
                                            className={`mx-auto ${partner.height}`}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
