import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function PastProjects() {
    const projects = [
        {
            id: "franchise-project",
            client: "Nationwide Franchise, South Africa",
            industry: "Fast Food Franchise",
            challenge:
                "Modernise and upgrade the current customer service and engagement platforms to meet the omni-channel demands.",
            solution: [
                "Complete digital transformation powered by AI customer experience solutions",
                "Consolidation of systems and migration to a globally recognised platform",
                "Seamless transition without operational disruption",
                "Implementation of robust foundation for future enhancements",
                "Comprehensive agent training and support program",
            ],
            results: [
                "Improved customer experience across all channels",
                "Leveraging cutting-edge technologies and AI to enhance engagement, loyalty and retention",
                "Enabled data capturing to further enhance internal reporting capabilities",
            ],
            image: "/path-to-customer-service-image.jpg",
        },
        {
            id: "political-project",
            client: "Ethical Journalism Business, Africa",
            industry: "Political Journalism",
            challenge:
                "Develop a platform for users to verify political adverts and distinguish between real and misinformation. ",
            solution: [
                "We built an easy reverse image search API system on WhatsApp. The user uploads a political ad image and the API searches for similar images from reputable sources such as published ads, to determine whether the image they saw had been edited.",
            ],
            results: [
                "Users are able to separate misinformation from real information.",
                "Giving power back to the users to stop the spread of misinformation.",
                "By using a familiar interface like WhatsApp makes it easier for users to utilise.",
            ],
            image: "/path-to-ar-app-image.jpg",
        },
        {
            id: "finance-project",
            client: "Investigation and Tracing Business, South Africa",
            industry: "Finance",
            challenge:
                "Develop a digital solution for financial managers to facilitate tracing individuals and provide sensitive information to agents.",
            solution: [
                "A unique, streamlined digital management solution to securely manage information, collect sensitive data and facilitate the tracing process digitally.",
                "We built a Web app for managers to manage and upload leads.",
                "We built a Mobile app for agents to access information and complete the investigation.",
            ],
            results: [
                "Paperless, digital data management.",
                "Secure application and portal.",
                "Managers and administrators tool that links both products.",
                "Real-time overview of investigations and collected data.",
            ],
        },
        {
            id: "software-project",
            client: "Software Provider, London",
            industry: "Marketing",
            challenge:
                "Merge real-life experiences with augmented, digital reality for short-term marketing campaigns and long-term brand loyalty.",
            solution: [
                "A unique, branded web app experience that uses state-of-the-art tech to deliver location specific augmented reality (AR) to users, anywhere.",
                "Set up a streamline system to register via WhatsApp link and allow play seamlessly.",
                "An AR interactive game experience based on location.",
                "Set up rewards system to encourage engagement that leads to rewards.",
            ],
            results: [
                "Increase in sales from rewards program.",
                "Data driven, real time insights of users and their locations.",
                "Increase in brand loyalty and engagement.",
                "Increase in customer engagement through WhatsApp.",
            ],
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        arrows: true,
        customPaging: (i) => (
            <div className="w-3 h-3 mx-1 rounded-full bg-white opacity-50 hover:opacity-100 transition-opacity duration-300" />
        ),
    }

    return (
        <section id="projects" className="py-16 bg-neutralLight">
            <div className="max-w-6xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12 text-white">
                    Success Stories
                </h2>

                <Slider {...settings} className="project-slider">
                    {projects.map((project) => (
                        <div key={project.id} className="px-2">
                            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                                <div className="p-8">
                                    <div className="flex flex-col lg:flex-row gap-8">
                                        <div className="w-full">
                                            <div className="flex items-center justify-between mb-6">
                                                <div>
                                                    <h3 className="text-2xl font-bold text-primary">
                                                        {project.client}
                                                    </h3>
                                                    <p className="text-secondary">
                                                        {project.industry}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="space-y-6">
                                                <div>
                                                    <h4 className="text-lg font-semibold text-primary mb-2">
                                                        The Challenge
                                                    </h4>
                                                    <p className="text-gray-600">
                                                        {project.challenge}
                                                    </p>
                                                </div>

                                                <div>
                                                    <h4 className="text-lg font-semibold text-primary mb-2">
                                                        Our Solution
                                                    </h4>
                                                    <ul className="list-none text-gray-600 space-y-1">
                                                        {project.solution.map(
                                                            (item, idx) => (
                                                                <li
                                                                    key={`${project.id}-solution-${idx}`}
                                                                    className="flex"
                                                                >
                                                                    <span className="mr-2">
                                                                        •
                                                                    </span>
                                                                    <span>
                                                                        {item}
                                                                    </span>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>

                                                <div>
                                                    <h4 className="text-lg font-semibold text-primary mb-2">
                                                        Key Results
                                                    </h4>
                                                    <ul className="list-none text-gray-600 space-y-1">
                                                        {project.results.map(
                                                            (result, idx) => (
                                                                <li
                                                                    key={`${project.id}-result-${idx}`}
                                                                    className="flex"
                                                                >
                                                                    <span className="mr-2">
                                                                        •
                                                                    </span>
                                                                    <span>
                                                                        {result}
                                                                    </span>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Custom styles for the carousel */}
            <style jsx>{`
                .project-slider .slick-dots {
                    bottom: -40px;
                }
                .project-slider .slick-prev,
                .project-slider .slick-next {
                    z-index: 10;
                }
                .project-slider .slick-prev {
                    left: -30px;
                }
                .project-slider .slick-next {
                    right: -30px;
                }
                .project-slider .slick-prev:before,
                .project-slider .slick-next:before {
                    color: white;
                }
            `}</style>
        </section>
    )
}

export default PastProjects
