// WhyChooseUs.js
import React from "react"

function WhyChooseUs() {
    const reasons = [
        {
            id: "call-centres",
            title: "Proven track record in supporting call centres",
            description:
                "We have a wealth of experience in assisting call centres improve their performance, streamline call routing automation and agent performance management.",
        },
        {
            id: "software",
            title: "Experience building software companies",
            description:
                "We have a proven track record of building successful software companies and know exactly what it takes to bring a product from concept to market.",
        },
        {
            id: "award-winning",
            title: "Award-winning solutions",
            description:
                "Codified software products and customer service solutions have been recognised by industry experts. This is a testament to the quality of our work.",
        },
        {
            id: "large-brands",
            title: "Work with large brands in South Africa",
            description:
                "Codified has helped some of the largest brands in South Africa providing us the experience and expertise to handle even the most complex projects.",
        },
        {
            id: "methodologies",
            title: "Tried and tested methodologies",
            description:
                "From piloting and automation, our digital solutions are crafted from experience and in-house knowledge, to ensure that each project is successful in meeting your business objectives.",
        },
    ]

    return (
        <section id="why-us" className="py-12 bg-white">
            <div className="max-w-4xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">
                    Why Choose Us
                </h2>

                <div className="space-y-4">
                    {reasons.map((reason) => (
                        <div
                            key={reason.id}
                            className="bg-gray-50 rounded-lg p-6"
                        >
                            <div className="flex items-center gap-2 mb-2">
                                <i className="bx bx-check-circle text-secondary text-xl"></i>
                                <h3 className="text-lg font-bold text-primary">
                                    {reason.title}
                                </h3>
                            </div>
                            <p className="text-gray-600">
                                {reason.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs
